.modelHero {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/public/images/model-s.jpg");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.itemText {
    padding-top: 140px;
 }
 
 .itemText p {
     margin-top: 12px;
     text-align: center;
 }
 
 .buttonGroup {
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     gap: 30px;
     margin-bottom: 40px;
 }
 
 .LeftButton {
     height: 40px;
     width: 256px;
     color: #ffffff;
     border-radius: 100px;
     opacity: 0.85;
     text-transform: uppercase;
     font-size: 12px;
     background-color: rgba(23, 26, 32, 0.8);
     border: none;
     margin: 0px 10px;
 }
 
 .downArrow img {
     height: 40px;
     width: 40px;
     overflow-x: hidden;
     animation: animateDown infinite 1.5s;
 }

 .containerCustom {
    max-width: 1000px;
    margin: 60px auto;
 }

 .carDetailBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
 }

 .carDetail {
    width: 50%;
 }

 .carImage {
    width: 50%;
    height: 350px;
    overflow: hidden;
 }

 .carImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
 }

 .carDetail h2 {
    color: rgb(1 119 167);
 }

 .carDetail p {
    margin: 20px 0px;
    color: #ffffffa8;
 }

 .learnButton {
    height: 40px;
    width: 140px;
    color: #ffffff;
    border-radius: 100px;
    opacity: 0.85;
    text-transform: uppercase;
    font-size: 12px;
    background-color: rgb(13 149 205);
    border: none;
 }

 .sectionBox {
    background-color: #0d0d0ef5;
 }

 @media only screen and (max-width: 1024px){
   .modelHero {
     height: 60%;
   }
   .containerCustom {
      max-width: 80%;
      margin: 40px auto;
  }
  .carImage {
   height: 300px;
   overflow: hidden;
  }
  .carDetailBox {
   margin: 20px 0px;
 }
}

@media only screen and (max-width: 820px){
   .carDetailBox {
      gap: 30px;
      flex-direction: column;
      margin: 40px 0px;
  }
  .carDetail {
   width: 80%;
   margin: auto;
}
.carImage {
   width: 80%;
}
.carImage img {
   object-fit: cover;
}
}

@media only screen and (max-width: 540px){
  .logo {
   width: 75px;
    height: 30px;
    overflow: hidden;
  }
  .logo img {
   width: 100%;
   height: 100%;
   object-fit: contain;
  }
  .menu {
   margin-left: 0px;
}
.rightMenu ul li:last-child {
   display: none;
}
.containerHeader {
  padding: 0px 16px;
}
.itemText,
.shopText {
   padding-top: 100px !important;
}
.LeftButton,
.shopButton {
   width: 186px !important;  
}
.containerCustom {
   max-width: 95%;
   margin: auto;
}
.carDetail,
.carImage  {
   width: 90%;
   margin: auto;
}
.carImage {
   height: 260px;
}
}

@media only screen and (max-width: 428px){
   .menu ul, .rightMenu ul {
      gap: 10px;
      font-size: 10px;
   }
   .LeftButton,
.shopButton {
   width: 156px !important;
   font-size: 10px !important;
   letter-spacing: 0.5px;  
}
.carImage {
   height: 200px;
}
}




