.Container {
    height: 100vh;
}

@media only screen and (max-width: 1024px){
    .eRwRxP,
    .cjGnpZ,
    .iYFYA-D,
    .kka-Dwg,
    .jYjKCC,
    .gqDWrA {
      height: 60% !important;
    }
 }