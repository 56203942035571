* {
  box-sizing: border-box;
  font-family: 'Bebas Neue', cursive;
  /* color: #393c41; */
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

body {
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

a {
  text-decoration: none;
}

@keyframes animateDown {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(3px);
  }
}

