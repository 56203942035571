.shopHero {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("https://admin.idaoffice.org/wp-content/uploads/2019/10/%D0%BE%D1%84%D0%B8%D1%81-tesla.jpg");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}


.shopText {
    padding-top: 140px;
    color: #ffffff;
    text-align: center;
 }
 
 .shopText p {
     margin-top: 12px;
     color: #ffffff;
 }

 .shopButton {
    height: 40px;
    width: 256px;
    color: #ffffff;
    border-radius: 100px;
    opacity: 0.85;
    text-transform: uppercase;
    font-size: 12px;
    background-color: #000000;
    border: none;
    margin: 0px 10px;
}

@media only screen and (max-width: 1024px){
    .shopHero {
      height: 60%;
    }
 }