.modelHero3 {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/public/images/model-3.jpg");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.itemText {
    padding-top: 140px;
 }
 
 .itemText p {
     margin-top: 12px;
     text-align: center;
 }
 
 .buttonGroup {
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     gap: 30px;
     margin-bottom: 40px;
 }
 
 .LeftButton {
     height: 40px;
     width: 256px;
     color: #ffffff;
     border-radius: 100px;
     opacity: 0.85;
     text-transform: uppercase;
     font-size: 12px;
     background-color: rgba(23, 26, 32, 0.8);
     border: none;
     margin: 0px 10px;
 }
 
 .downArrow img {
     height: 40px;
     width: 40px;
     overflow-x: hidden;
     animation: animateDown infinite 1.5s;
 }

 @media only screen and (max-width: 1024px){
    .modelHero3 {
      height: 60%;
    }
 }