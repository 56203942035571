.itemText {
   padding-top: 140px;
}

.itemText h1 {
    text-align: center;
    color: #393c41;
}

.itemText p {
    margin-top: 12px;
    color: #393c41;
}

.buttonGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 40px;
}

.LeftButton {
    height: 40px;
    width: 256px;
    color: #ffffff;
    border-radius: 100px;
    opacity: 0.85;
    text-transform: uppercase;
    font-size: 12px;
    background-color: rgba(23, 26, 32, 0.8);
    border: none;
    margin: 0px 10px;
}

.downArrow img {
    height: 40px;
    width: 40px;
    overflow-x: hidden;
    animation: animateDown infinite 1.5s;
}







