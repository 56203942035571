.containerHeader {
    min-height: 60px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    top: 0;
    left: 0;
    right: 0;
    background-color: #b0d2ed;
    z-index: 1;
}

.menu {
    margin-left: 50px;
}

.menu ul li a,
.rightMenu ul li a {
    color: #393c41;
} 

.menu ul,
.rightMenu ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
}